import React from "react"

export const UsersTermsOfServiceData = () => {
  const styles = `
  ol.lst-kix_txb33tp11c72-0 {
    list-style-type: none;
  }
  ol.lst-kix_txb33tp11c72-1 {
    list-style-type: none;
  }
  ol.lst-kix_txb33tp11c72-2 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-7 {
    list-style-type: none;
  }
  .lst-kix_h3pcy4x3skj0-0 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-0;
  }
  ol.lst-kix_h3pcy4x3skj0-8 {
    list-style-type: none;
  }
  ol.lst-kix_txb33tp11c72-3.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-3 0;
  }
  ol.lst-kix_h3pcy4x3skj0-5 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-6 {
    list-style-type: none;
  }
  .lst-kix_h3pcy4x3skj0-0 > li:before {
    content: "" counter(lst-ctn-kix_h3pcy4x3skj0-0, lower-latin) ") ";
  }
  ol.lst-kix_h3pcy4x3skj0-3.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-3 0;
  }
  .lst-kix_txb33tp11c72-1 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-1;
  }
  .lst-kix_h3pcy4x3skj0-1 > li:before {
    content: "" counter(lst-ctn-kix_h3pcy4x3skj0-1, lower-roman) ") ";
  }
  ol.lst-kix_txb33tp11c72-0.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-0 0;
  }
  .lst-kix_txb33tp11c72-6 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-6;
  }
  .lst-kix_h3pcy4x3skj0-4 > li:before {
    content: "(" counter(lst-ctn-kix_h3pcy4x3skj0-4, lower-roman) ") ";
  }
  ol.lst-kix_txb33tp11c72-6.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-6 0;
  }
  .lst-kix_h3pcy4x3skj0-2 > li:before {
    content: "" counter(lst-ctn-kix_h3pcy4x3skj0-2, decimal) ") ";
  }
  .lst-kix_h3pcy4x3skj0-6 > li:before {
    content: "" counter(lst-ctn-kix_h3pcy4x3skj0-6, lower-latin) ". ";
  }
  .lst-kix_h3pcy4x3skj0-3 > li:before {
    content: "(" counter(lst-ctn-kix_h3pcy4x3skj0-3, lower-latin) ") ";
  }
  .lst-kix_h3pcy4x3skj0-7 > li:before {
    content: "" counter(lst-ctn-kix_h3pcy4x3skj0-7, lower-roman) ". ";
  }
  .lst-kix_46bd1p3te8qz-7 > li:before {
    content: "\x25cb   ";
  }
  ol.lst-kix_h3pcy4x3skj0-3 {
    list-style-type: none;
  }
  ul.lst-kix_46bd1p3te8qz-2 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-4 {
    list-style-type: none;
  }
  ul.lst-kix_46bd1p3te8qz-1 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-1 {
    list-style-type: none;
  }
  ul.lst-kix_46bd1p3te8qz-4 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-2 {
    list-style-type: none;
  }
  ul.lst-kix_46bd1p3te8qz-3 {
    list-style-type: none;
  }
  .lst-kix_46bd1p3te8qz-8 > li:before {
    content: "\x25a0   ";
  }
  ul.lst-kix_46bd1p3te8qz-6 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-0 {
    list-style-type: none;
  }
  ul.lst-kix_46bd1p3te8qz-5 {
    list-style-type: none;
  }
  .lst-kix_h3pcy4x3skj0-5 > li:before {
    content: "(" counter(lst-ctn-kix_h3pcy4x3skj0-5, decimal) ") ";
  }
  .lst-kix_h3pcy4x3skj0-6 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-6;
  }
  ul.lst-kix_46bd1p3te8qz-8 {
    list-style-type: none;
  }
  .lst-kix_txb33tp11c72-2 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-2;
  }
  ul.lst-kix_46bd1p3te8qz-7 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-7.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-7 0;
  }
  .lst-kix_h3pcy4x3skj0-1 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-1;
  }
  ol.lst-kix_h3pcy4x3skj0-4.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-4 0;
  }
  .lst-kix_h3pcy4x3skj0-7 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-7;
  }
  .lst-kix_h3pcy4x3skj0-4 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-4;
  }
  ol.lst-kix_txb33tp11c72-2.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-2 0;
  }
  .lst-kix_txb33tp11c72-7 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-7;
  }
  ol.lst-kix_h3pcy4x3skj0-1.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-1 0;
  }
  .lst-kix_h3pcy4x3skj0-3 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-3;
  }
  .lst-kix_txb33tp11c72-4 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-4;
  }
  .lst-kix_txb33tp11c72-8 > li:before {
    content: "" counter(lst-ctn-kix_txb33tp11c72-8, decimal) ". ";
  }
  .lst-kix_txb33tp11c72-5 > li:before {
    content: "(" counter(lst-ctn-kix_txb33tp11c72-5, decimal) ") ";
  }
  ol.lst-kix_txb33tp11c72-5.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-5 0;
  }
  ol.lst-kix_txb33tp11c72-8.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-8 0;
  }
  .lst-kix_txb33tp11c72-7 > li:before {
    content: "" counter(lst-ctn-kix_txb33tp11c72-7, lower-roman) ". ";
  }
  ol.lst-kix_h3pcy4x3skj0-8.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-8 0;
  }
  .lst-kix_txb33tp11c72-6 > li:before {
    content: "" counter(lst-ctn-kix_txb33tp11c72-6, lower-latin) ". ";
  }
  .lst-kix_txb33tp11c72-1 > li:before {
    content: "" counter(lst-ctn-kix_txb33tp11c72-1, lower-roman) ") ";
  }
  ol.lst-kix_h3pcy4x3skj0-5.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-5 0;
  }
  .lst-kix_txb33tp11c72-0 > li:before {
    content: "" counter(lst-ctn-kix_txb33tp11c72-0, lower-latin) ") ";
  }
  .lst-kix_txb33tp11c72-4 > li:before {
    content: "(" counter(lst-ctn-kix_txb33tp11c72-4, lower-roman) ") ";
  }
  ol.lst-kix_txb33tp11c72-1.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-1 0;
  }
  .lst-kix_txb33tp11c72-5 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-5;
  }
  .lst-kix_txb33tp11c72-3 > li:before {
    content: "(" counter(lst-ctn-kix_txb33tp11c72-3, lower-latin) ") ";
  }
  .lst-kix_txb33tp11c72-2 > li:before {
    content: "" counter(lst-ctn-kix_txb33tp11c72-2, decimal) ") ";
  }
  .lst-kix_46bd1p3te8qz-2 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_46bd1p3te8qz-4 > li:before {
    content: "\x25cb   ";
  }
  ol.lst-kix_h3pcy4x3skj0-2.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-2 0;
  }
  ul.lst-kix_46bd1p3te8qz-0 {
    list-style-type: none;
  }
  ol.lst-kix_h3pcy4x3skj0-0.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-0 0;
  }
  .lst-kix_46bd1p3te8qz-3 > li:before {
    content: "\x25cf   ";
  }
  .lst-kix_txb33tp11c72-8 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-8;
  }
  .lst-kix_h3pcy4x3skj0-5 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-5;
  }
  .lst-kix_h3pcy4x3skj0-8 > li:before {
    content: "" counter(lst-ctn-kix_h3pcy4x3skj0-8, decimal) ". ";
  }
  .lst-kix_h3pcy4x3skj0-2 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-2;
  }
  .lst-kix_46bd1p3te8qz-6 > li:before {
    content: "\x25cf   ";
  }
  ol.lst-kix_txb33tp11c72-4.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-4 0;
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_46bd1p3te8qz-5 > li:before {
    content: "\x25a0   ";
  }
  .lst-kix_txb33tp11c72-3 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-3;
  }
  ol.lst-kix_txb33tp11c72-7.start {
    counter-reset: lst-ctn-kix_txb33tp11c72-7 0;
  }
  .lst-kix_h3pcy4x3skj0-8 > li {
    counter-increment: lst-ctn-kix_h3pcy4x3skj0-8;
  }
  .lst-kix_txb33tp11c72-0 > li {
    counter-increment: lst-ctn-kix_txb33tp11c72-0;
  }
  ol.lst-kix_h3pcy4x3skj0-6.start {
    counter-reset: lst-ctn-kix_h3pcy4x3skj0-6 0;
  }
  ol.lst-kix_txb33tp11c72-7 {
    list-style-type: none;
  }
  ol.lst-kix_txb33tp11c72-8 {
    list-style-type: none;
  }
  .lst-kix_46bd1p3te8qz-0 > li:before {
    content: "\x25cf   ";
  }
  ol.lst-kix_txb33tp11c72-3 {
    list-style-type: none;
  }
  ol.lst-kix_txb33tp11c72-4 {
    list-style-type: none;
  }
  ol.lst-kix_txb33tp11c72-5 {
    list-style-type: none;
  }
  .lst-kix_46bd1p3te8qz-1 > li:before {
    content: "\x25cb   ";
  }
  ol.lst-kix_txb33tp11c72-6 {
    list-style-type: none;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c15 {
    padding-top: 20pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c11 {
    background-color: #ffffff;
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c7 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c4 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
  }
  .c5 {
    padding-top: 18pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c2 {
    padding-top: 12pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c17 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
  }
  .c16 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c6 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c10 {
    padding: 0;
    margin: 0;
  }
  .c14 {
    color: inherit;
    text-decoration: inherit;
  }
  .c12 {
    font-size: 16pt;
  }
  .c9 {
    height: 11pt;
  }
  .c13 {
    font-weight: 700;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  `

  return (
    <div>
      <style type="text/css">{styles}</style>
      <h1 class="c15" id="h.yrv7021g2fop">
        <span>Terms of Use</span>
      </h1>
      <p class="c1">
        <span class="c4">
          Sideline Sports a Islandi ehf, org. no. 520601-2790 (&ldquo;Sideline
          Sports&rdquo;) provides digital services in the form of website,
          mobile and desktop applications for registering members, enabling
          coaching organization, communication and session scheduling and other
          sport club and coaching functions to sports clubs and other
          organizations according to agreements and in association for the
          Customer&rsquo;s members.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span>
          These Terms of Use apply to all use of the Sideline Sports&#39;s
          applications (&ldquo;
        </span>
        <span class="c13">Applications</span>
        <span class="c0">
          &rdquo;) by Users, as defined below. Acceptance of these Terms of Use
          by Users is a condition for the use of the Applications.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c0">
          To be able to accept these Terms of Use, Users must be over 16 years
          old. If a User is under 16 years old, a guardians&rsquo; consent is
          required.
        </span>
      </p>
      <h2 class="c5" id="h.hqjt246vndus">
        <span class="c8">1. Definitions</span>
      </h2>
      <p class="c1">
        <span class="c4">Customer</span>
      </p>
      <p class="c1">
        <span>
          Sport club, organization or person who enters a business relationship
          with Sideline Sports in order to use the Services.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c4">Intellectual Property Rights</span>
      </p>
      <p class="c1">
        <span class="c0">
          Refers to all registered and unregistered intellectual property
          rights, including but not limited to, copyright and related rights,
          patents, design rights, trademarks, business name rights, design
          rights, domain names, know-how, and database rights.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c4">Software</span>
      </p>
      <p class="c1">
        <span class="c0">
          Refers to the software that Sideline Sports uses in providing the
          Applications as well as upgrades and amendments.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c4">System</span>
      </p>
      <p class="c1">
        <span class="c0">
          Refers to the server systems used by Sideline Sports as well as the
          Software and other code developed by Sideline Sports to provide the
          Services.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c4">Services</span>
      </p>
      <p class="c1">
        <span class="c0">
          Refers to the services provided by Sideline Sports according to an
          agreement with the Customer, including a license to use the
          Applications. On the basis of such an agreement with the Customers,
          Users are provided with Services in accordance with these Terms of
          Use.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c4">User/s</span>
      </p>
      <p class="c1">
        <span class="c0">
          Refers to individuals who use the Applications, based on
          Customers&rsquo; agreement with Sideline Sports. The term Users refer
          to trainers, athletes and athletes&rsquo; guardians.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c5">
        <span class="c8">2. License and Updates</span>
      </p>
      <p class="c2">
        <span class="c0">
          2.1. On the basis of these Terms of Use, Sideline Sports provides
          Users a limited, non-transferable, license to use the Applications on
          the condition that Users have a valid contract with a Sideline
          Sports&rsquo; Customer.
        </span>
      </p>
      <p class="c2">
        <span class="c0">
          2.2. From time to time Sideline Sports may automatically update the
          Software and change the Services to improve performance, enhance
          functionality, reflect changes to the System or address security
          issues. Alternatively Sideline Sports may ask Users to update the
          Application for these reasons.
        </span>
      </p>
      <p class="c2">
        <span class="c0">
          2.3. If Users choose not to install such updates or if Users opt out
          of automatic updates Users may not be able to continue using the
          Application and the Services.
        </span>
      </p>
      <p class="c5">
        <span class="c8">3. Intellectual Property Rights</span>
      </p>
      <p class="c2">
        <span class="c0">
          3.1. All Intellectual Property Rights in the Application and the
          Services, including the Software, throughout the world belong to
          Sideline Sports and its licensors and no Intellectual Property Rights
          are transferred to Users on the basis of these Terms of Use.
        </span>
      </p>
      <p class="c2">
        <span class="c0">
          3.2. A limited, non-exclusive, license to use the Application and the
          Services is provided to Users, on the basis of an agreement between
          Sideline Sports and Customers and on the basis of these Terms of Use.
          The license is solely provided for personal use.
        </span>
      </p>
      <p class="c5">
        <span class="c8">4. Users&rsquo; Rights</span>
      </p>
      <p class="c2">
        <span class="c0">4.1 Users are not authorized to:</span>
      </p>
      <ol class="c10 lst-kix_h3pcy4x3skj0-0 start" start="1">
        <li class="c2 c6 li-bullet-0">
          <span class="c0">
            copy the Application or Services, except as part of the normal use
            of the Application or where it is necessary for the purpose of
            back-up or operational security;
          </span>
        </li>
        <li class="c2 c6 li-bullet-0">
          <span class="c0">
            translate, merge, adapt, vary, alter or modify, the whole or any
            part of the Application or Services;
          </span>
        </li>
        <li class="c2 c6 li-bullet-0">
          <span class="c0">
            permit the Application or the Services or any part of them to be
            combined with, or become incorporated in, any other programs, except
            as necessary to use the Application and the Services; or
          </span>
        </li>
        <li class="c2 c6 li-bullet-0">
          <span class="c0">
            disassemble, de-compile, reverse engineer or create derivative works
            based on the whole or any part of the Application or the Services,
            attempt to do any such things, except to the extent authorized under
            mandatory legislation.
          </span>
        </li>
      </ol>
      <p class="c11">
        <span class="c0">4.2 Users must:</span>
      </p>
      <ol class="c10 lst-kix_txb33tp11c72-0 start" start="1">
        <li class="c7 c6 li-bullet-0">
          <span class="c0">
            not use the Application or any Service in any unlawful manner, for
            any unlawful purpose, or in any manner inconsistent with these Terms
            of Use, or act fraudulently or maliciously, for example, by hacking
            into or inserting malicious code, such as viruses, or harmful data,
            into the Application, any Service or any operating system;
          </span>
        </li>
        <li class="c7 c6 li-bullet-0">
          <span class="c0">
            not infringe Sideline Sports&lsquo; Intellectual Property Rights or
            those of any third party in relation to use of the Application or
            any Service;
          </span>
        </li>
        <li class="c7 c6 li-bullet-0">
          <span class="c0">
            not transmit any material that is defamatory, offensive or otherwise
            objectionable in relation to use of the Application or any Service;
          </span>
        </li>
        <li class="c7 c6 li-bullet-0">
          <span class="c0">
            not use the Application or any Service in a way that could damage,
            disable, overburden, impair or compromise the System or security or
            interfere with other users; and
          </span>
        </li>
        <li class="c6 c7 li-bullet-0">
          <span class="c0">
            not collect or harvest any information or data from the Software or
            the System.
          </span>
        </li>
      </ol>
      <p class="c1">
        <span class="c0">
          4.3. Sideline Sports reserves the right, without incurring liability,
          to disconnect the User from the Applications if the User can be
          considered to be violating any provision of these Terms of Use.
        </span>
      </p>
      <h2 class="c5" id="h.hykn0hybjzpu">
        <span class="c8">5. Data Protection </span>
      </h2>
      <p class="c1">
        <span>
          5.1 Sideline Sports takes the privacy of its Customers and other third
          parties very seriously. Sideline Sports&rsquo; Privacy Policy at{" "}
        </span>
        <span class="c17">
          <a
            class="c14"
            href="https://sidelinesports.com/privacyPolicy"
          >
            https://sidelinesports.com/privacyPolicy
          </a>
        </span>
        <span class="c0">
          &nbsp;is hereby incorporated into these Terms by reference.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c0">
          5.2 In order to provide the Services, Sideline Sports must process
          User&rsquo;s personal data, including for the purpose of the
          Customer&#39;s administration and ability to maintain membership
          registers and other functions to facilitate coaching organization,
          scheduling and performance analysis and evaluations and for the
          purpose of the Customer&#39;s communication and increasing the
          Users&rsquo; benefit from the Applications through notifications sent
          to Users with reminders for training, matches, and notifications and
          news. When processing personal data on behalf of the Customers,
          Sideline Sports acts as a data processor in the meaning of applicable
          data protection legislation, and the Customers act as data
          controllers. A reference is made to the Customers&rsquo; privacy
          policies for information on these data processing activities.
        </span>
      </p>
      <h2 class="c5" id="h.lzqx32rkb59l">
        <span class="c8">6. Liability</span>
      </h2>
      <p class="c1">
        <span class="c0">
          6.1. Sideline Sports is not liable for, and does not compensate for,
          damage caused by operational interruptions, interruptions, or other
          technical circumstances that affect the use of the Applications as
          long as otherwise does not follow from mandatory law. Sideline Sports
          is also not obliged to compensate for damage directly or indirectly
          caused by the use of the Applications as long as otherwise does not
          follow from mandatory law.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c0">
          6.2. Under no circumstances is Sideline Sports liable for indirect or
          consequential damages (including but not limited to, loss of profit,
          loss of business, income loss, loss of goodwill, missed expected
          savings, and loss of data) unless the damages were caused by Sideline
          Sports&#39;s intentional or grossly negligent actions.
        </span>
      </p>
      <h2 class="c5" id="h.83vnldbj42wj">
        <span class="c8">7. Updating of Sideline Sports Applications</span>
      </h2>
      <p class="c1">
        <span class="c0">
          In order to optimize the Applications, Sideline Sports reserves the
          right, when necessary, to perform updates, upgrades, and/or
          modifications of the Services and how it is provided and designed,
          including adjustments to the business model and revenue model.
        </span>
      </p>
      <h2 class="c5" id="h.atidzp7b1get">
        <span class="c8">8. Term and Termination </span>
      </h2>
      <p class="c1">
        <span class="c0">
          8.1. These Terms of Use come into effect as soon as the User accepts
          them and thereafter applies until the agreement with the Customer ends
          or the User or Sideline Sports terminates the license of use on the
          basis of these Terms of Use.
        </span>
      </p>
      <p class="c1 c9">
        <span class="c0"></span>
      </p>
      <p class="c1">
        <span class="c0">
          8.2. Upon termination of these Terms of Use, for whatever reason, all
          the User&#39;s right to use the Applications and the Services shall
          cease with immediate effect.
        </span>
      </p>
      <h2 class="c5" id="h.xh8jq39r3l97">
        <span class="c8">9. Changes and Assignment</span>
      </h2>
      <p class="c1">
        <span class="c0">
          9.1. &nbsp;Sideline Sports reserves the right to make changes to these
          Terms of Use. Such changes will be made available to the User through
          appropriate communication channels. The User has the option to ask for
          termination of their account if the User does not agree to the updated
          Terms of Use.
        </span>
      </p>
      <p class="c2">
        <span>
          9.2. Sideline Sports may transfer its rights and obligations under
          these Terms of Use to another{" "}
        </span>
        <span>organisation</span>
        <span class="c0">, subject to a notification to Users. </span>
      </p>
      <h2 class="c5" id="h.305ib5r6i0th">
        <span class="c8">10. Applicable Law and Disputes</span>
      </h2>
      <p class="c1">
        <span class="c0">
          Icelandic law shall apply to the Terms of Use. Disputes arising from,
          or related to, the Terms of Use shall be resolved by an Icelandic
          court.
        </span>
      </p>
      <p class="c2 c10">
        <span class="c0"></span>
      </p>
      <p class="c2" style={{ marginTop: 30 }}>
        <span class="c0">Last updated: February 27, 2024</span>
      </p>
      <p class="c6">
        <span class="c0"></span>
      </p>      
    </div>
  )
}
